// Modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { loginPageComponent } from '@pages/login-page/login-page.component';

const routes: Routes = [
  // {
  //   path: '',
  //  data: {
  //     title: 'Login Page'
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: loginPageComponent,
  //       data: {
  //         title: ''
  //       }
  //     }
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
  static components = [ loginPageComponent ];
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// Services
import { AuthService } from '@core/auth/auth.service';

// Components
import { ModalSaveUserAssociationComponent } from '@shared/modal/modal-save-user-association/modal-save-user-association.component';

// Thirds
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { Credentials } from '@app/core/models/credentials.model';
import { async } from '@angular/core/testing';
import { environment } from '@environments/environment';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class loginPageComponent implements OnInit, OnDestroy {

  activeToken: string = null;
  activeTokenMsg: {type: 'success' | 'error', msg: string} = null;

  subscription: Subscription = new Subscription();

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  // Modal
  modalRef: BsModalRef;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private modalService: BsModalService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.activeToken = this.activatedRoute.snapshot.queryParamMap.get('active');
    if (this.activeToken != null){
      this.active();
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
        return;
    }
    this.loading = true;
    this.subscription.add(this.authService.login(this.f.username.value, this.f.password.value)
      .subscribe({
          next: async data => {
            if(!data.acceptedTerms) {
              this.acceptTerms(data);
              this.loading = false;
            }
            else {
              localStorage.setItem('crendentials', JSON.stringify(data));
              localStorage.setItem('token', data.token);
              localStorage.setItem('authorities', JSON.stringify(data.authorities));
              this.router.navigate(['/painel']);
            }
          },
          error:  () => {
            this.loading = false;
            const message: string = `<b>Usuário não ativo ou E-mail/Senha inválido</b>.<hr><small>Verifique seu email para validar sua conta.<br>Caso não lembre sua senha de acesso, clique no link <b>ESQUECEU SUA SENHA?</b></small>`
            Swal.fire('Falha Autenticação', message, 'error');
          }
      }));
  }

  register(){
    this.modalRef = this.modalService.show(ModalSaveUserAssociationComponent, {class: 'modal-dialog-centered'});
  }

  active(){
    this.subscription.add(this.authService.active(this.activeToken).subscribe({
      next: () => Swal.fire('Conta Ativada', 'Sua conta foi ativada com sucesso!', 'success'),
      error: err => Swal.fire('Ooops', `${err.error.message}!`, 'error'),
      complete: () => this.router.navigate(['/login'])
    }));
  }

  acceptTerms(credentials: any) {
    const linkAccepTerms = environment.linkAccepTerms;
    const linkPrivatePolicy = environment.linkPrivatePolicy;
    Swal.fire({
      title: 'Termos de Uso e Compromisso',
      html: `Para prosseguir com seu acesso, você deve estar de acordo com nossos <a href="${linkAccepTerms}" target="_blank">Termos de Uso e Compromisso</a> e <a href="${linkPrivatePolicy}" target="_blank">Políticas de Privacidade</a>.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'De acordo',
      cancelButtonText: 'Não estou de acordo',
    }).then(result => {
      if (result.isConfirmed){
        this.authService.acceptTerms(credentials).toPromise()
        .then(() => {
          localStorage.setItem('crendentials', JSON.stringify(credentials));
          localStorage.setItem('token', credentials.token);
          localStorage.setItem('authorities', JSON.stringify(credentials.authorities));
          this.router.navigate(['/painel']);
        })
        .catch(() => this.router.navigate(['/login']))
        .finally(() => Swal.close())
      }
      else {
        this.router.navigate(['/login']);
      }
    });
  }

  async recoveryPassword(){
    Swal.fire({
      title: 'Informe seu e-mail',
      input: 'email',
      inputPlaceholder: 'E-mail',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed){
        Swal.fire({
          title: 'Enviando ...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        const email = result.value;
        await this.authService.recoveryPassword(email).toPromise()
        .then(
          async () => await Swal.fire('Senha Redefinida', `Sua nova senha foi enviado para: <p><b>${email}</b></p>`, 'success')
        ).catch(
          async error => await Swal.fire('Ooops', `${error.error.message}`, 'error')
        );
      }
    });
  }

}

import { SharedModule } from '@shared/shared.module';
// Angular Modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
registerLocaleData(localeBr, 'pt');

// App Modules
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from '@pages/home-page/home.module';
import { LoginModule } from './pages/login-page-gdm/login.module';
import { LoginModuleMain } from './pages/login-page/login.module';
import { LoginModuleAccor } from './pages/login-page-accor/login.module';
import { CoreModule } from '@core/core.module';

// Services
import { AuthService } from '@core/auth/auth.service';

// Components
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    LoginModule,
    LoginModuleAccor,
    LoginModuleMain,
    CoreModule
  ],
  providers: [
    AuthService,
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
